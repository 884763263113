<template>
  <div class="accountPage">
    <div class="app h5MaxWidth">
      <div class="subject">
        <div class="accountCard h5MaxWidth">
          <div class="userCard display alignCenter" @click="goPage('/profile')">
            <div class="portrait">
              <img
                :src="config.base + info.avatar"
                alt=""
                style="width: 80px; height: 80px; border-radius: 100px"
              />
            </div>
            <div class="userinfo display alignCenter justifyDetween flex1">
              <div class="useName flex1">
                <p id="nickName">{{ info.nickname }}</p>
                <span id="accountTel">{{ info.mobile }}({{ info.id }})</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main">
        <div class="groupRaer radius15PX">
          <div class="bankCardHolder">
            <div id="bankCard" class="radius151500PX" @click="showPop = true">
              <div class="display alignStart radius7PX">
                <div class="text flex1" style="min-height: 30px">
                  <p v-if="bankInfo">{{ bankInfo.name }}</p>
                  <span v-if="bankInfo">{{ bankInfo.account }}</span>
                </div>
                <div class="icon">
                  <i class="iconfont icon-master-card"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="balanceCard radius15PX">
            <div class="specialTitle flex1">
              <img src="./../images/pageTitle02.svg" alt="" />
            </div>
            <div class="capitalItem flex5">
              <div class="gongGeNAV">
                <ul class="display alignEnd alignEnd">
                  <li class="flex1" @click="goPage('/withdrawRecord')">
                    <div class="radius7PX">
                      <p id="withdrawBalance">
                        {{
                          parseFloat(info.cashBalance) +
                          parseFloat(info.givingMoney)
                        }}
                      </p>
                      <span>Withdrawable</span>
                    </div>
                  </li>
                  <li class="balance flex1" @click="goPage('/rechargeRecord')">
                    <div class="radius7PX">
                      <h3 id="balance">{{ info.credits }}</h3>
                      <span>Recharge wallet</span>
                    </div>
                  </li>
                  <li class="flex1" @click="goPage('/banlanceRecord')">
                    <div class="radius7PX">
                      <p id="depositBalance">{{ income }}</p>
                      <span>Promotion Income</span>
                    </div>
                  </li>
                </ul>
                <ul class="display alignEnd alignEnd">
                  <li class="balance flex1">
                    <div class="radius7PX">
                      <p id="sumReward">Inquire</p>
                      <span>Invest Income</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div class="actionBar display alignCenter alignStretch">
              <div
                class="depositBtn clickBtn_S flex5 display alignCenter justifyCenter"
                @click="goPage('/deposit')"
              >
                Deposit
              </div>
              <div
                class="withdrawBtn clickBtn_S flex4 display alignCenter justifyCenter"
                @click="goPage('/withdraw')"
              >
                Withdraw
              </div>
            </div>
          </div>
        </div>
        <div class="vipCard radius15PX">
          <div
            class="vipLevelMark display alignCenter radius500PX"
            @click="goPage('/vipMSP')"
            id="vip"
          >
            <div class="vip_ICON radius50P">
              <img
                src="./../images/lv0.svg"
                v-if="info.level == 'VIP0'"
                alt=""
              />
              <img
                src="./../images/lv1.svg"
                v-if="info.level == 'VIP1'"
                alt=""
              />
              <img
                src="./../images/lv2.svg"
                v-if="info.level == 'VIP2'"
                alt=""
              />
              <img
                src="./../images/lv3.svg"
                v-if="info.level == 'VIP3'"
                alt=""
              />
              <img
                src="./../images/lv4.svg"
                v-if="info.level == 'VIP4'"
                alt=""
              />
              <img
                src="./../images/lv5.svg"
                v-if="info.level == 'VIP5'"
                alt=""
              />
              <img
                src="./../images/lv6.svg"
                v-if="info.level == 'VIP6'"
                alt=""
              />
              <img
                src="./../images/lv7.svg"
                v-if="info.level == 'VIP7'"
                alt=""
              />
              <img
                src="./../images/lv8.svg"
                v-if="info.level == 'VIP8'"
                alt=""
              />
            </div>
            <div class="experienceBar flex1 display alignCenter justifyDetween">
              <div class="vip_LV">
                <p>{{ info.level }}</p>
                <span></span>
              </div>
              <div class="progressBar flex1 radius500PX">
                <div
                  class="progress radius500PX"
                  id="invitationComplianceExtra10Progress"
                  :style="
                    'width:' +
                    (info.cashTotal / info.nextLevelTotal) * 100 +
                    '%'
                  "
                >
                  <div class="glow radius500PX"></div>
                </div>
              </div>
              <div class="copies">
                <p id="invitationComplianceExtra10ProgressText">
                  {{ parseFloat(info.cashTotal).toFixed(0) }}/{{
                    parseFloat(info.nextLevelTotal).toFixed(0)
                  }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="accountNavMenu">
          <div class="navMenu">
            <div class="functionMenu">
              <div class="listItem">
                <div class="groupRaer radius15PX">
                  <ul class="cell">
                    <li
                      class="radius7PX display alignCenter"
                      @click="goPage('/message')"
                    >
                      <div
                        class="icon radius7PX display alignCenter justifyCenter"
                      >
                        <i class="iconfont icon-notification-bing"></i>
                      </div>
                      <div class="infoBar display alignCenter flex1">
                        <div class="titleLabel flex1">
                          <p>Messages</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="groupRaer radius15PX">
                  <ul class="cell">
                    <li
                      class="radius7PX display alignCenter"
                      @click="goPage('/about')"
                    >
                      <div
                        class="icon radius7PX display alignCenter justifyCenter"
                      >
                        <i class="iconfont icon-aboutus2"></i>
                      </div>
                      <div class="infoBar display alignCenter flex1">
                        <div class="titleLabel flex1">
                          <p>About us</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="groupRaer radius15PX">
                  <ul class="cell">
                    <li
                      class="myInfo radius7PX display alignCenter"
                      @click="goPage('/profile')"
                    >
                      <div
                        class="icon radius7PX display alignCenter justifyCenter"
                      >
                        <i class="iconfont icon-userinfo"></i>
                      </div>
                      <div class="infoBar display alignCenter flex1">
                        <div class="titleLabel flex1">
                          <p>MyInfo</p>
                          <span class="warning"></span>
                        </div>
                      </div>
                    </li>

                    <li
                      class="radius7PX display alignCenter"
                      @click="goPage('/changeLoginPassword')"
                    >
                      <div
                        class="icon radius7PX display alignCenter justifyCenter"
                      >
                        <i class="iconfont icon-lock"></i>
                      </div>
                      <div class="infoBar display alignCenter flex1">
                        <div class="titleLabel flex1">
                          <p>LoginPWD</p>
                        </div>
                      </div>
                    </li>

                    <li
                      class="radius7PX display alignCenter"
                      @click="goPage('/tradePassword')"
                    >
                      <div
                        class="icon radius7PX display alignCenter justifyCenter"
                      >
                        <i class="iconfont icon-shield-tick"></i>
                      </div>
                      <div class="infoBar display alignCenter flex1">
                        <div class="titleLabel flex1">
                          <p>PayPWD</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="groupRaer radius15PX">
                  <ul class="cell">
                    <li
                      class="radius7PX logout display alignCenter"
                      @click="goPage('/login')"
                    >
                      <div class="infoBar display alignCenter flex1">
                        <div class="titleLabel flex1">
                          <p>Log Out</p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-popup
      v-model="showPop"
      position="top"
      :style="{ background: 'none', top: '20%' }"
    >
      <div class="promptMain et-02s radius15PX">
        <div class="promptTitleBar display alignCenter justifyDetween"></div>
        <div class="promptCont">
          <div class="changeBankCard">
            <div class="onlineService">
              <div class="likeness">
                <img src="./../images/OnlineService.png" alt="" />
              </div>
            </div>
            <div class="changeTip explain">
              <h3>Change prompt</h3>
              <p>
                For the safety of your funds ,If you want to change your
                withdrawal bank account, please contact online customer service!
              </p>
            </div>
            <div class="changeTip explain">
              <p>WhatsApp Online</p>
              <span>9:00 AM to 18:00 PM</span>
            </div>
          </div>
          <div class="actionBar display alignCenter alignStretch">
            <div
              class="okCancelBtn clickBtn_S flex1 display alignCenter justifyCenter"
              @click="showPop = false"
            >
              OK
            </div>
            <div
              class="openServiceBtn clickBtn_S flex2 display alignCenter justifyCenter"
              @click="openService()"
            >
              WhatsApp Online
            </div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import config from "@/utils/config.js";
export default {
  data() {
    return {
      config,
      showPop: false,
      info: {},
      bankInfo: "",
      income: 0,
    };
  },
  components: {},
  methods: {
    goPage(page) {
      this.$router.push(page);
    },
    openService() {
      window.open(
        "https://api.whatsapp.com/send/?phone=919115969974&text&type=phone_number&app_absent=0"
      );
    },
  },
  mounted() {
    this.uid = this.getItem("uid");
    //获取用户详情
    this.getAjax("api/user/index?uid=" + this.uid, {}, "get").then((res) => {
      this.info = res.data.info;
    });
    this.getAjax(
      "api/user/index",
      {
        uid: this.uid,
      },
      "post"
    ).then((res) => {
      this.income = res.Income.promotionIncome;
    });

    function replaceMiddleWithAsterisk(str) {
      if (str.length < 4) {
        return "字符串长度必须大于等于4";
      }
      var pattern = /(\d{2})\d{4}(\d{2})/; // 匹配中间四位数字
      var replacedStr = str.replace(pattern, function (match, p1, p2) {
        var asterisks = "*".repeat(4);
        return p1 + asterisks + p2;
      });
      return replacedStr;
    }

    this.getAjax("api/user/account?uid=" + this.uid, {}, "get").then((res) => {
      this.bankInfo = res.detail;
      if (this.bankInfo) {
        this.bankInfo.account = replaceMiddleWithAsterisk(
          this.bankInfo.account
        );
      }
    });
  },
};
</script>

<style scoped lang="less">
@charset "utf-8";

.accountPage .main {
  top: -50px;
  position: relative;
}

.accountPage .main > div {
  margin: 0 10px;
}

.accountCard .userCard {
  position: relative;
  z-index: 1;
}

.accountCard .userCard > div:not(:first-child) {
  padding: 0 0 0 10px;
}

.userinfo .useName span:empty,
.userinfo .useName p:empty,
.vipCard .vip_LV p:empty,
.vipCard .vip_LV span:empty,
.bankCardHolder .addBank .text p:empty {
  width: 110px;
  max-width: 120px;
  height: 10px;
  margin: 0 0 5px;
  background-color: var(--black3);
}

.userinfo .useName span:empty,
.userinfo .useName p:empty {
  background-color: #fff;
}

.userinfo .useName p {
  font-size: 26px;
  font-weight: 700;
  color: #fff;
}

.userinfo .useName p span {
  font-size: 20px;
  font-weight: 300;
  color: #fff;
  opacity: 0.5;
}

.userinfo .useName > span {
  font-size: 20px;
  font-weight: 300;
  color: #fff;
  opacity: 0.8;
}

.bankCardHolder .addBank .text p:empty {
  background-color: #fff;
  opacity: 0.3;
}

.userinfo .useName span:empty {
  height: 5px;
  display: block;
}

.accountCard .userCard .portrait .frame {
  width: 80px;
  height: 80px;
  background-color: #fff;
}

.experienceBar {
  padding: 0 0 0 10px;
}

.accountPage .main .vipCard {
  margin: 10px;
  padding: 20px;
  background-color: #fff;
  z-index: -1;
}

.vipCard .vipLevelMark,
.vipCard .vip_LV {
  width: 100%;
  padding: 0;
}

.vipCard .experienceBar .copies p {
  font-size: 16px;
  font-weight: 300;
  color: var(--black2);
}

.accountPage .main div.balanceCard {
  padding: 10px 20px 20px;
  background-color: #fff;
  position: relative;
  z-index: 1;
}

.accountPage .balanceCard .capitalItem {
  margin: 0 0 20px;
}

.accountPage .balanceCard .capitalItem .gongGeNAV ul {
  margin: 5px 0;
  padding: 5px 0;
}

.accountPage .balanceCard .capitalItem .gongGeNAV ul li > div {
  padding: 0;
}

.accountPage .main div.groupRaer {
  background-color: #fff;
}

.bankCardHolder {
  margin: 0;
  top: -1px;
  position: relative;
}

.bankCardHolder .icon {
  right: 20px;
  opacity: 0.3;
  position: absolute;
}

.bankCardHolder .icon i {
  font-size: 32px;
  color: #fff;
}

.bankCardHolder #bankCard {
  width: 100%;
  padding: 20px;
  display: inline-block;
  background-color: var(--a_2);
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
}

.bankCardHolder .text p {
  font-size: 18px;
  color: #fff;
}

.bankCardHolder .text span {
  font-size: 22px;
  font-weight: 700;
  color: #fff;
}

.bankCardHolder .addBank .text p {
  opacity: 0.8;
}

.bankCardHolder .addBank .text span {
  opacity: 0.5;
}

.emendation .delCard {
  margin: 20px 0;
}

.emendation .delCard ul li {
  padding: 5px 0;
}

.accountPage .main div.functionMenu .groupRaer {
  margin: 10px 0;
  background-color: #fff;
  -moz-box-shadow: 0px 0px 20px 5px rgba(0, 40, 14, 0);
  -webkit-box-shadow: 0px 0px 20px 5px rgba(0, 40, 14, 0);
  box-shadow: 0px 0px 20px 5px rgba(0, 40, 14, 0);
}

.accountPage .main div.functionMenu .cell li {
  padding: 15px;
}

.listItem .cell li.logout {
  text-align: center;
}

.listItem .cell li.logout p {
  color: var(--r_3);
}

.serviceMenu {
  display: none;
}

.serviceMenu > div {
  margin: 20px 0;
  padding: 10px;
}

.serviceMenu .channelList {
  display: none;
}

.serviceMenu .channel .gongGeNAV ul li {
  padding: 10px;
}

.serviceMenu .channel .explain {
  margin: 0;
  padding: 0 10px;
}

.serviceMenu .channel .explain p {
  font-size: 12px;
}

.gongGeNAV li.logout .icon i,
.gongGeNAV li.logout p {
  color: var(--r_3);
}

.promptCont .changeBankCard {
  margin: 0 0 10px;
  padding: 0 0 10px;
}

.promptCont .changeBankCard .onlineService {
  width: 80px;
  margin: 0 0 10px;
}

.promptCont .changeBankCard .onlineService .likeness,
.promptCont .changeBankCard .onlineService .likeness img {
  width: 100%;
}

.promptCont .changeBankCard .changeTip {
  margin: 10px 0;
}

.promptCont .openServiceBtn {
  background-color: var(--a_2);
}

.promptCont .okCancelBtn {
  background-color: var(--b_2);
}
</style>
